
	$(document).ready(function() {
					var modal_content, modal_screen;
					$(document).ready(function() {
						av_legality_check();
					});
					av_legality_check = function() {
						if ($.cookie('is_legal') == "yes") {} else {
							av_showmodal();
							$(window).on('resize', av_positionPrompt);
						}
					};
					av_showmodal = function() {
						modal_screen = $('<div id="modal_screen" class="age_gate"></div>');
						modal_content = $('<div id="modal_content" style="display:none"></div>');
						var modal_content_wrapper = $('<div id="modal_content_wrapper" class="content_wrapper"></div>');
						var modal_regret_wrapper = $('<div id="modal_regret_wrapper" class="content_wrapper" style="display:none;"></div>');
						var content_agegate = $('<center style="background-color: #000;padding: 50px;"><img src="https://www.md2020wine.com/wp-content/themes/md2020/assets/images/md2020-logo.jpg"></center><center style="padding: 30px;"><div class="agree"><input type="checkbox" id="agree" class="btn-default" required></input></a> I agree to the <a data-fancybox data-type="iframe" data-src="https://www.md2020wine.com/terms-of-service.html" href="javascript:;">Terms of Service</a> and <a data-fancybox data-type="iframe" data-src="https://www.md2020wine.com/privacy-policy.html" href="javascript:;">Privacy Policy</a></div><h1>Are You of Legal Drinking Age?</h1><nav><ul><li><a href="#" class="av_btn av_go" rel="yes" id="yes">Yes</a></li><li><a href="#" class="av_btn av_no" rel="no" id="no">No</a></li></nav><p>You must be at least 21 years old to view this site.<br> By clicking "yes" you affirm that you are at least 21 years old.</p></center>');
						var regret_text = $('<h2>You must be 21 years of age or older to view this site.</h2>');
						modal_content_wrapper.append(content_agegate);
						modal_regret_wrapper.append(regret_text);
						modal_content.append(modal_content_wrapper, modal_regret_wrapper);
						$('body').append(modal_screen, modal_content);
						av_positionPrompt();
						modal_content.find('a.av_btn').on('click', av_setCookie);
					};
					av_setCookie = function(e) {
						e.preventDefault();
						var is_legal = $(e.currentTarget).attr('rel');
						$.cookie('is_legal', is_legal, {
							expires: 30,
							path: '/'
						});
						if ($("#agree").is(':checked') && (is_legal == "yes")) {
							av_closeModal();
							$(window).off('resize');
						} else if (is_legal == "no") {
							av_showRegret();
						} else if (!$("#agree").is(':checked')) {
							alert('You must agree to the terms of service.');
						}
					};
					av_closeModal = function() {
						modal_content.fadeOut();
						modal_screen.fadeOut();
					};
					av_showRegret = function() {
						modal_screen.addClass('nope');
						modal_content.find('#modal_content_wrapper').hide();
						modal_content.find('#modal_regret_wrapper').show();
					};
					av_positionPrompt = function() {
						var top = ($(window).outerHeight() - $('#modal_content').outerHeight()) / 2;
						var left = ($(window).outerWidth() - $('#modal_content').outerWidth()) / 2;
						modal_content.css({
							'top': top,
							'left': left
						});
						if (modal_content.is(':hidden') && ($.cookie('is_legal') != "yes")) {
							modal_content.fadeIn('slow');
						}
					};
				});
	
